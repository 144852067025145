/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'

import { useState, useEffect } from 'react'

import Layout from '../../components/Layout'
import Wrapper from '../../components/Wrapper'

import Info from './Info'
import Donation from '../shared/Donation'

import { TYPE } from '../../utils/enum/type'

import { CampaignProvider } from './context'

// eslint-disable-next-line react/prop-types
const TemplateCampaign = ({ pageContext, location }) => {
  const { campaign } = pageContext

  const [isCheckout, setCheckout] = useState(() => {
    if (typeof window === 'undefined') return false

    return JSON.parse(sessionStorage.getItem('@denario_app/settings'))
      ?.isCheckout
  })

  return (
    <CampaignProvider value={campaign}>
      <Layout>
        <Wrapper>
          {!isCheckout && <Info className="hide-on-checkout" />}
          <Donation type={TYPE.campaign} />
        </Wrapper>
      </Layout>
    </CampaignProvider>
  )
}

PropTypes.propTypes = {
  pageContext: PropTypes.shape({
    campaign: PropTypes.shape({
      id: PropTypes.number.isRequired,
      campaignName: PropTypes.string.isRequired,
      daysUntilEnd: PropTypes.number.isRequired,
      distance: PropTypes.number.isRequired,
      endTime: PropTypes.number.isRequired,
      goalValue: PropTypes.number.isRequired,
      imagePath: PropTypes.string.isRequired,
      institutionId: PropTypes.number.isRequired,
      institutionName: PropTypes.string.isRequired,
      isFinished: PropTypes.bool.isRequired,
      isPermanent: PropTypes.bool.isRequired,
      startTime: PropTypes.string.isRequired,
      totalDonatedPercentage: PropTypes.number.isRequired,
      totalApprovedDonatedValue: PropTypes.number.isRequired,
    }),
  }),
}

export default TemplateCampaign
