import api from './api'

export async function fetchInstitutions(page) {
  return api.get(`/institutions`, {
    params: {
      page,
    },
  })
}

export async function queryInstitutions(query) {
  return api.get(`/institutions`, {
    params: {
      page: 1,
      size: 4,
      query,
    },
  })
}

export async function fetchAllInstitutions() {
  return api.get(`/institutions`, {
    params: {
      size: 9999,
    },
  })
}

export async function fetchInstitutionLastDonation(institutionsId, campaignId) {
  if (campaignId) {
    return api.get(
      `/institutions/${institutionsId}/last-donation/${campaignId}`
    )
  }

  return api.get(`/institutions/${institutionsId}/last-donation/`)
}
