/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { Fragment, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-scroll'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import { toast } from 'react-toastify'

import FlexContainer from '../../../components/FlexContainer'
import Card from '../../../components/Card'
import Title from '../../../components/Title'
import Text from '../../../components/Text'
import Button from '../../../components/Button'

import { formatMoney, currencies } from '../../../utils/money'
import { isEquivalent } from '../../../utils/objectCompare'
import { getCampaign } from '../../../services/campaigns'

import IconFacebook from '../../../images/ic-facebook.svg'
import IconTwitter from '../../../images/icons/ic-twitter.svg'
import IconWhatsApp from '../../../images/icons/ic-whatsapp.svg'
import IconremainingDays from '../../../images/icons/ic-dias-restantes-mobile.svg'
import IconSupporters from '../../../images/icons/ic-apoiadores-mobile.svg'

import CampaignContext from '../context'

import { fetchInstitutionLastDonation } from '../../../services/institutions'

const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.lightgray};
    border-radius: 5px;
    height: 10px;
    width: 100%;
  `}
`

const InfoCard = styled(Card)`
  ${({ theme }) => css`
    ${theme.queries.large} {
      margin-left: 40px;
      min-width: 323px;
      min-height: 430px;
    }
  `}
`
const Progress = styled.div`
  ${({ percentage }) => css`
    background: #21a44b;
    border-radius: 5px;
    height: 10px;
    width: ${percentage}%;
  `}
`

const ProgressBar = ({ percentage }) => (
  <Container>
    <Progress percentage={percentage} />
  </Container>
)

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
}

const Info = () => {
  const intl = useIntl()
  const data = useContext(CampaignContext)

  const [campaignEndTime, setEndTime] = useState()
  const [hasGoalValue, setHasGoalValue] = useState(false)

  useEffect(() => {
    if (data.endTime) {
      setEndTime(new Date(data.endTime).toLocaleDateString())
    }

    if (data.goalValue) {
      setHasGoalValue(true)
      updateDonationProgress()
    }
  }, [])

  const [amount, setAmount] = useState(data.totalApprovedDonatedValue)
  const [percentage, setPercentage] = useState(data.totalDonatedPercentage)
  const [donorsAmount, setTotalDonors] = useState(data.totalDonors)

  const updateDonationProgress = async () => {
    try {
      const response = await getCampaign(data.id)
      const {
        totalApprovedDonatedValue,
        totalDonatedPercentage,
        totalDonors,
      } = response.data

      setAmount(totalApprovedDonatedValue)
      setPercentage(totalDonatedPercentage)
      setTotalDonors(totalDonors)
    } catch (error) {
      toast.info(intl.formatMessage({ id: 'toast_messages.error.donation' }))
    }
  }

  useEffect(() => {
    if (hasGoalValue) {
      const interval = setInterval(updateDonationProgress, 10000)
      return () => clearInterval(interval)
    }
  })

  return (
    <FlexContainer
      className="hide-on-checkout"
      css={css`
        flex-direction: column;
      `}
    >
      <FlexContainer
        css={theme => css`
          flex-direction: column;
          margin-bottom: 30px;

          ${theme.queries.large} {
            flex-direction: row;
          }
        `}
      >
        <Card upImagesrc={data.images && data.images[0]} flex borderDetail>
          <div
            css={theme => css`
              padding: 24px 27px 0;
              margin: auto 0 10px;

              ${theme.queries.small} {
                padding: 15px 25px;
              }
            `}
          >
            <Title
              mobileSize="20"
              mobileHeight="28"
              lineHeight="40"
              size="30"
              height="50"
            >
              {data.campaignName}
            </Title>
            <Text mobileSize="14" size="14" height="22">
              {data.institution.name}
            </Text>
          </div>
        </Card>

        <div
          className={data.goalValue === 0 ? 'hide' : ''}
          css={theme => css`
            ${theme.queries.large} {
              margin-left: 40px;
              min-width: 323px;
            }
            ${theme.queries.small} {
              margin-top: 30px;

              &.hide {
                display: none;
              }
            }
          `}
        >
          <InfoCard>
            <FlexContainer
              css={theme => css`
                align-items: center;
                height: 100%;
                flex-direction: column;
                justify-content: space-between;
                padding: 37px 20px 30px;

                ${theme.queries.large} {
                  padding: 37px 30px 30px;
                  min-height: 430px;
                }

                ${theme.queries.small} {
                  padding: 15px 20px;
                }
              `}
            >
              <div
                css={css`
                  align-self: flex-start;
                `}
              >
                <Text
                  color="olivergreen"
                  marginBottom="5"
                  marginBottomMobile="10"
                  mobileSize="24"
                  size="29"
                >
                  {formatMoney(currencies.BRL)(amount)}
                </Text>
                {hasGoalValue && (
                  <Text
                    color="gray"
                    marginBottom="10"
                    mobileHeight="18"
                    mobileSize="14"
                    size="14"
                  >
                    de {formatMoney(currencies.BRL)(data.goalValue)}
                  </Text>
                )}
              </div>

              {hasGoalValue && <ProgressBar percentage={percentage} />}

              <div
                css={theme => css`
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: space-between;
                  flex-wrap: wrap;
                `}
              >
                {campaignEndTime && (
                  <FlexContainer
                    css={theme => css`
                      align-items: center;
                      display: flex;
                      justify-content: flex-start;
                      margin-top: 40px;
                      width: 100%;

                      ${theme.queries.small} {
                        margin-top: 15px;
                      }
                    `}
                  >
                    <IconremainingDays
                      css={theme => css`
                        height: 24px;
                        margin-right: 15px;
                        width: 24px;
                        align-self: flex-start;
                        path {
                          fill: ${theme.colors.deepblue};
                        }

                        ${theme.queries.small} {
                          height: 20px;
                          width: 20px;
                        }
                      `}
                    />
                    <FlexContainer
                      css={css`
                        flex-direction: column;
                      `}
                    >
                      <Text
                        color="deepblue"
                        marginBottom="10"
                        marginBottomMobile="5"
                        mobileSize="14"
                        size="18"
                      >
                        {data.daysUntilEnd}{' '}
                        <FormattedMessage id="pages.campaigns.days_left" /> - (
                        {campaignEndTime})
                      </Text>
                    </FlexContainer>
                  </FlexContainer>
                )}

                <FlexContainer
                  css={theme => css`
                    align-items: center;
                    justify-content: flex-start;
                    margin-top: 32px;
                    width: 100%;

                    svg {
                      margin-right: 20px;
                    }

                    ${theme.queries.small} {
                      margin-top: 10px;

                      svg {
                        margin-right: 15px;
                      }
                    }
                  `}
                >
                  <IconSupporters
                    css={theme => css`
                      path {
                        fill: #b81d24;
                      }

                      ${theme.queries.small} {
                        height: 26px;
                        width: 20px;
                      }
                    `}
                  />
                  <Text color="firebrick" mobileSize="14">
                    {donorsAmount}{' '}
                    {donorsAmount === 1
                      ? intl.formatMessage({
                          id: 'pages.campaigns.supporters',
                        })
                      : intl.formatMessage({
                          id: 'pages.campaigns.supporters',
                        })}
                  </Text>
                </FlexContainer>
              </div>

              <Fragment>
                <FlexContainer
                  css={theme => css`
                    display: flex;
                    flex-direction: column;
                    margin: 40px 0 36px;

                    ${theme.queries.small} {
                      display: none;
                    }

                    ${theme.queries.large} {
                      align-items: center;
                      flex-direction: row;
                      display: flex;
                    }
                  `}
                >
                  <Text
                    color="gray"
                    mobileSize="16"
                    marginBottomMobile="18"
                    size="16"
                    css={theme => css`
                      ${theme.queries.large} {
                        margin-right: 7px;
                      }
                    `}
                  >
                    <FormattedMessage id="pages.campaigns.share_with" />
                  </Text>
                  <FlexContainer
                    css={css`
                      justify-content: center;

                      img + svg {
                        margin-left: 7px;
                      }
                      svg + svg {
                        margin-left: 7px;
                      }
                    `}
                  >
                    <img src={IconFacebook} />
                    <IconWhatsApp />
                    <IconTwitter />
                  </FlexContainer>
                </FlexContainer>
              </Fragment>
              <Link to="donation-section" spy smooth duration={500}>
                <Button
                  stretch
                  uppercase
                  css={theme => css`
                    ${theme.queries.small} {
                      display: none;
                    }
                  `}
                >
                  <FormattedMessage id="go_donate" />
                </Button>
              </Link>
            </FlexContainer>
          </InfoCard>
        </div>
      </FlexContainer>
      <Text
        mobileSize="14"
        css={theme => css`
          ${theme.queries.large} {
            margin-top: 40px;
          }
        `}
      >
        {data.description}
      </Text>
    </FlexContainer>
  )
}

export default Info
